import { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { Theme } from '../../theme'
import IconButton from '../IconButton'
import { layout, media, device, colors } from '../../theme/viewer'
import {
  FluentProvider,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from '@fluentui/react-components'

export const fadeInBg = keyframes`
  from {
    opacity: 0.001;
  }
  to {
    opacity: 1;
  }
`

export const slideIn = keyframes`
  from {
    transform: translateY(-200vh);
  }
  to {
    transform: translateY(0);
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    animation: ${fadeInBg} ease-in 0.25s;
  }
`

const PopupElm = styled.aside`
  background-color: var(--body-background);

  ${media.down(device.tablet)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: calc(var(--vh, 1vh) * 100);
  `}

  ${media.up(device.tablet)`
    
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
    background-color: var(--body-background);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    animation: ${slideIn} 0.75s;
    z-index: 2;
    max-height: calc(var(--vh, 1vh) * 100);
    border-radius: 10px;

    &.size-sm {
      width: 320px;
    }

    &.size-md {
      width: 480px;
    }

    &.size-lg {
      width: 800px;
    }
  `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  min-height: 108px;
  padding: 0 10px 0 1.5rem;
  color: var(--text);

  ${media.down(device.mobile)`
    height: 52px;
   
  `}

  h3, h4 {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  ${media.down(device.tablet)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
     min-height: 60px;
  `}
`

const Content = styled.div`
  padding: 0 1.5rem;
  flex: 1 1;
  overflow: auto;
  ${media.down(device.tablet)`
    position: absolute;
    top: 62px;
    bottom: 60px;
    left: 0;
    right: 0;
    height: 74vh;
  `}

  ${media.down(device.mobile)`
    top: 52px;
  `}
`

const Footer = styled.div`
  ${media.down(device.tablet)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    flex-wrap: wrap;
  `}
  ${media.down(device.mobile)`
    flex-direction: column;
  `}
  padding: 10px 1.5rem;
  margin: 10px 0;
  /* display: flex;
  align-items: center;
  justify-content: center;
  > * {
  } */
`

const PopupComponent = ({
  title,
  header,
  size,
  children,
  footer,
  onClose,
  isCustom = false,
}) => {
  const overlay = useRef()
  const container = useRef()

  useEffect(() => {
    const handleClose = (e) => {
      if (onClose && e.target === e.currentTarget) {
        onClose()
      }
    }
    overlay.current.addEventListener('click', handleClose)
    return () => {
      if (overlay.current) {
        overlay.current.removeEventListener('click', handleClose)
      }
    }
  })

  useEffect(() => {
    const sbWidth = window.innerWidth - document.body.clientWidth
    const updateSize = () => {
      // update content width
      const width = window.innerWidth - sbWidth
      const $header = document.querySelector('.header')
      if ($header) {
        $header.style.width = `${width}px`
      }
      document.body.style.width = `${width}px`
      document.body.style.overflow = 'hidden'
      document.getElementById('root').classList.add('modal')

      // update vh height for mobile
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window.removeEventListener('resize', updateSize)
      document.body.style.overflow = null
      document.body.style.width = null
      const $header = document.querySelector('.header')
      if ($header) {
        $header.style.width = null
      }
      document.getElementById('root').classList.remove('modal')
    }
  }, [])

  return (
    <Overlay ref={overlay}>
      <PopupElm ref={container} className={size ? `size-${size}` : 'size-md'}>
        {title && (
          <Header>
            <h3>{title}</h3>
            {onClose && (
              <IconButton
                iconClassName="popup__close__icon"
                onClick={onClose}
                icon="close"
              />
            )}
          </Header>
        )}
        {isCustom ? children : <Content>{children}</Content>}

        {footer && <Footer className="btnRow">{footer()}</Footer>}
      </PopupElm>
    </Overlay>
  )
}

const Popup = (props) => {
  return ReactDOM.createPortal(
    <FluentProvider
      theme={{
        ...Theme.lightTheme,
        colorNeutralBackground3: '#eeeeee',
      }}
      style={{ background: tokens.colorNeutralBackground3 }}
    >
      <PopupComponent {...props} />
    </FluentProvider>,
    document.querySelector('#modal')
  )
}

export default Popup
