import React from 'react'
import styled from 'styled-components'
import { colors, device, layout, media } from '../../theme'
import { Tab } from '@fluentui/react-components'

export const PreviewWrapper = styled.div`
  background-color: var(--body-background);
  // display: flex;
  ${media.up(device.tablet)`
    flex-grow: 1;
    order: 1;
    min-height: 250px;
    max-height: 100%;
    overflow: auto;
  `}
`
export const Thumbnail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  //   height: 300px;
  img {
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 300px;
    box-shadow: ${layout.elevate(2)};
    border: solid 1px var(--body-background);
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`
export const FileItem = styled.li`
  padding: 4px 0px;
  margin: 0px;
  animation: 0.25s ease-in 0s 1 normal none running iFEGFp;
`

export const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const PopupBody = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 270px;
  min-height: 270px;
  ${media.down(device.tablet)`
         max-height: unset;
         min-height: unset;
         display: flex;
     `}
`
export const ConfigWrapper = styled.div`
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
`
export const CustomTab = styled(Tab)`
  span {
    color: var(--text) !important;
  }
`
