import { useContext, useState, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  WebViewerOptions,
  PdfViewerCanvas,
} from '@pdf-tools/four-heights-pdf-web-viewer'
import { saveAs } from 'file-saver'
import * as microsoftTeams from '@microsoft/teams-js'
import styled from 'styled-components'

import { downloadFile } from '../../../store/viewer/downloadApiSlice'
import { fileUpload } from '../../../service/document/fileUpload'
import { uploadFile } from '../../../service/oneDrive/uploadFile'
import { createId } from '../../../utils/createId'
import saveToOneDrive from '../utils/saveToOneDrive'
import config from '../../../lib/config'
import { useMessage, withMessageBox } from '../../../controls/MessageBox'
import { MessageType } from '../../../utils/enums'
import Popup from '../../../controls/Popup'
import OneDriveFilePicker from '../../../components/OneDriveFilePicker'

import { device, media } from '../../../theme'
import ButtonRow from '../../../controls/ButtonRow'
import Button from '../../../controls/Button'
import { startHub, stopHub } from '../../../store/hub/hubMiddleware'
import { fileDownload } from '../../../service/document/getDownloadUrl'
import { TeamsFxContext } from '../../../components/TeamFxContextProvider'
import { uploadFileToOneDrive } from '../../../store/drive/Actions/oneDriveFileActions'
import { updateReadWriteField } from '../../../store/teamsAuth/teamsAuthSlice'
import FormSection from '../../../controls/FormSection'
import FormRow from '../../../controls/FormRow'
import NeeConsentPopup from '../../../controls/NeedConsentPopup'

let pdfViewer = undefined
export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  ${media.down(device.tablet)`
    position: absolute;
    top: 62px;
    bottom: 60px;
    left: 0;
    right: 0;
    height: 74vh;
  `}

  ${media.down(device.mobile)`
    top: 52px;
  `}
`
export const CustomButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`
const PdfViewerContext = createContext()

function getDocumentFromUrlParameters() {
  const urlParams = new URLSearchParams(window.location.search)

  urlParams.forEach((value, key) => {})
  let doc = undefined
  // const sharedDocumentParams = urlParams.get('fileurl')
  // if (sharedDocumentParams) {
  //   doc = {
  //     type: 'shared',
  //     path: window.atob(sharedDocumentParams),
  //     expiration: new Date(urlParams.get('e')),
  //     filename: urlParams.get('n'),
  //     size: urlParams.get('s'),
  //   }
  // } else {
  const url = urlParams.get('fileurl')
  if (url) {
    doc = {
      type: 'onedrive',
      filename: urlParams.get('name'),
      url,
    }
  }

  window._doc = doc
  return doc
}

export const PdfViewerProvider = withMessageBox((props) => {
  const { children } = props

  function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }
  const { showMessage } = useMessage()
  const dispatch = useDispatch()
  const {
    connectionId: hubConnectionId,
    status,
    docIds,
  } = useSelector((state) => state.hub)
  const { items, driveId, history } = useSelector((state) => state.oneDrive)
  const isReadWrite = useSelector((state) => state.teamsAuth.isReadWrite)
  const { teamsUserCredential } = useContext(TeamsFxContext)

  // state hooks
  const [actions, setActions] = useState({})
  const [viewerCreated, setViewerCreated] = useState(false)
  const [viewerLoaded, setViewerLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [passwordReqired, setPasswordReqired] = useState(false)
  const [fileBlob, setFileBlob] = useState(false)
  const [documentOutline, setDocumentOutline] = useState()
  const [showAnnotationbar, setShowAnnotationbar] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [zoom, setZoom] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [viewerError, setViewerError] = useState(0)
  const [filename, setFilename] = useState()
  const [modules, setModules] = useState([])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isConsentPopup, setIsConsentPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleResize = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  // Function to trigger the print dialog
  function triggerPrint(dataUrl, fileInfo) {
    try {
      if (isMobile()) {
        microsoftTeams.teamsCore.print(dataUrl)
      } else {
        const newWindow = window.open(
          dataUrl,
          fileInfo.filename,
          'height=800,width=600'
        )

        if (newWindow) {
          newWindow.focus()
          newWindow.print()

          // Check if the window is closed and clean up
          const intervalId = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(intervalId)
              window.URL.revokeObjectURL(dataUrl)
            }
          }, 1000) // Check every second
        } else {
          showMessage(
            'Failed to open new window for printing.',
            MessageType.Error
          )
          console.error('Failed to open new window for printing.')
          window.URL.revokeObjectURL(dataUrl)
        }
      }
    } catch (error) {
      showMessage(error.message, MessageType.Error)
    }
  }

  const fileUploadToStorage = async () => {
    try {
      const data = await pdfViewer.saveFile(false)
      const dataFile = new File([data], filename, {
        type: 'application/pdf',
      })
      // dataFile.name = filename

      const docProcessing = { storageType: 'cdoc', toolName: 'Pdf Editor' }
      const onprogress = (prams) => {}
      const thumbnail = 88
      let connection
      if (status != 'connected') {
        connection = await dispatch(startHub())
      }
      let connectionId = connection?.payload?.connectionId || hubConnectionId
      const uploadData = await fileUpload(
        docProcessing.storageType,
        dataFile,
        createId(),
        docProcessing.toolName,
        connectionId,
        onprogress,
        thumbnail
      )
      // uploadRes[0].name = filename
      return uploadData
      // const saveOneDriveRes = await saveToOneDrive(uploadRes, {})
    } catch (error) {
      console.error(error)
    } finally {
      if (!docIds.length) {
        dispatch(stopHub())
      }
    }
  }

  // actions
  const createPdfViewer = (viewerElement, options) => {
    if (viewerCreated) {
      throw new Error('PdfViewer is allready created')
    }
    const appLoaded = async () => {
      setViewerLoaded(true)

      setModules(pdfViewer.options.options.modules)

      const fileInfo = getDocumentFromUrlParameters()
      if (!fileInfo) {
        setViewerError('Invalid file')
        return
      } else if (
        fileInfo.type === 'shared' &&
        fileInfo.expiration < new Date()
      ) {
        setViewerError('The requested document has expired.')
        return
      }

      // loading file

      let fileBlob = undefined
      try {
        const resFile = await fetch(fileInfo.url)

        fileBlob = await resFile.blob()

        // if (fileInfo.type === 'storage') {
        //   fileBlob = await downloadFile(fileInfo.url, fileInfo.storageType)
        // } else if (fileInfo.type === 'shared') {
        //   fileBlob = await getSharedDocument(fileInfo)
        // }
        if (!fileBlob) {
          throw new Error()
        }
        fileBlob.name = 'test.pdf'

        setFilename(fileBlob.name)
      } catch (err) {
        setViewerError('Failed to load pdf document')
        return
      }

      const actions = {
        nextPage: () => {
          pdfViewer.nextPage()
        },
        previousPage: () => {
          pdfViewer.previousPage()
        },
        zoomIn: () => {
          pdfViewer.zoomIn()
        },
        zoomOut: () => {
          pdfViewer.zoomOut()
        },
        setZoom: (zoom) => {
          pdfViewer.setZoom(zoom)
        },
        setFitMode: (fitMode) => {
          pdfViewer.setFitMode(fitMode)
        },
        goTo: (destination) => {
          pdfViewer.goTo(destination)
        },
        addImage: (image) => {
          pdfViewer.modules.forEach((module) => {
            if (module.name === 'ImageAnnotationModule') {
              module.createImage(image)
            }
          })
        },
        addFreeTextAnnotation: () => {
          pdfViewer.modules.forEach((module) => {
            if (module.name === 'FreetextAnnotationModule') {
              pdfViewer.activateModule('FreetextAnnotationModule')
            }
          })
        },
        openFile: async (blob, password) => {
          try {
            await pdfViewer.openBlob(blob, password)
            //pdfViewer.pdfViewerApi.setBorderSize(1)
            const pageCount = pdfViewer.getPageCount()
            setPageCount(pageCount)

            const zoom = pdfViewer.getZoom()
            setZoom(zoom)

            setPasswordReqired(false)
            setFileBlob(null)
            setInitialized(true)
            setFilename(blob.name)
            return true
          } catch (err) {
            if (err.message === 'password required') {
              setPasswordReqired(true)
              setFileBlob(blob)
              return false
            }
            setViewerError('Failed to load pdf document')
            return false
          }
        },
        save: async () => {
          try {
            // Save the file using pdfViewer
            const data = await pdfViewer.saveFile(false)

            if (isMobile()) {
              // if (true) {
              const resFileUpload = await fileUploadToStorage()

              const file = {
                name: fileInfo.filename,
                docBlobRef: resFileUpload.pop().docBlobRef,
                storageType: 'cdoc',
              }
              showMessage('File download started', MessageType.Success)
              await fileDownload([file])
            } else {
              const newBlob = new Blob([data], { type: 'application/pdf' })
              showMessage('File download  started', MessageType.Success)
              saveAs(newBlob, fileInfo.filename)
            }
          } catch (error) {
            showMessage(error.message, MessageType.Error)
            console.log(`Error: ${error.message}`)
          }
        },

        saveFile: async () => {
          const data = await pdfViewer.saveFile(false)
          const fileBlob = new Blob([data], {
            type: 'application/pdf',
            name: fileInfo.filename,
          })
          return fileBlob
        },
        print: async () => {
          const data = await pdfViewer.saveFile(false)
          const printFileBlob = new Blob([data], {
            type: 'application/pdf',
            name: fileInfo.filename,
          })
          const dataUrl = window.URL.createObjectURL(printFileBlob)
          triggerPrint(dataUrl, fileInfo)
        },
        saveToOneDrive: async () => {
          //console.log('saveToOneDrive')

          // const data = await pdfViewer.saveFile(false)
          // const dataFile = new Blob([data], {
          //   type: 'application/pdf',
          // })
          // dataFile.name = fileInfo.filename

          // const fileId = createId()
          // const connectionId = createId()
          // const uploadRes = await fileUpload(
          //   'cdoc',
          //   dataFile,
          //   fileId,
          //   'pdfEditor',
          //   connectionId,
          //   () => {
          //     return {}
          //   }
          // )
          // uploadRes[0].name = fileInfo.filename

          // const saveOneDriveRes = await saveToOneDrive(uploadRes, {})

          setIsPopupOpen(true)
          setFilename(fileInfo.filename)
        },
        toggleAnnotationBar: () => {
          // setShowAnnotationbar(!showAnnotationbar)
        },
        activateModule: async (moduleName) => {
          pdfViewer.activateModule(moduleName)
        },
      }

      const annotationBar = document.querySelector('.pwv-annotationbar')
      if (annotationBar) {
        annotationBar.addEventListener('click', actions.toggleAnnotationBar)

        const toolPicker = document.querySelector(
          '.pwv-annotationbar-tool-picker'
        )

        if (toolPicker) {
          let _submenuEventAttached = false
          toolPicker.addEventListener('click', (e) => {
            const target = e.currentTarget
            if (target.classList.contains('pwv-dropdown-open')) {
              if (_submenuEventAttached) {
                actions.toggleAnnotationBar()
              } else {
                e.stopPropagation()
              }
            }

            const buttons = target.querySelectorAll('button.pwv-commandbar-btn')

            ;[].forEach.call(buttons, function (btn) {
              _submenuEventAttached = true
              btn.addEventListener('click', actions.toggleAnnotationBar, {
                once: true,
              })
            })
          })
        }
      }

      await actions.openFile(fileBlob)
      setActions(actions)

      const documentOutlines = await pdfViewer.getDocumentOutline()
      if (documentOutlines.length > 0) {
        setDocumentOutline(documentOutlines)
      }
      // pdfViewer.pdfViewerApi.setBorderSize(1)
    }

    window.addEventListener('resize', handleResize)

    const viewerOptions = new WebViewerOptions(options)
    pdfViewer = new PdfViewerCanvas(
      viewerElement,
      config.VIEWER_KEY,
      viewerOptions
    )
    pdfViewer.addEventListener('appLoaded', appLoaded)
    pdfViewer.addEventListener('zoom', setZoom)
    pdfViewer.addEventListener('firstVisiblePage', setCurrentPage)
    pdfViewer.addEventListener('error', (err) => {
      setViewerError(err.toString())
    })
    setViewerCreated(true)
  }
  const checkConsent = (callback) => {
    if (!isReadWrite) {
      setIsConsentPopup(true)
    } else {
      callback && callback()
    }
  }

  const fileUploadHandler = async () => {
    try {
      setLoading(true)
      const data = await pdfViewer.saveFile(false)
      const dataFile = new File([data], filename, {
        type: 'application/pdf',
      })
      const itemPath = history.reduce(
        (acc, curr) => (curr.name ? acc.concat(curr.name) : acc),
        []
      )
      itemPath.push(filename)
      const res = await dispatch(
        uploadFileToOneDrive({
          teamsUserCredential,
          name: filename,
          itemPath: itemPath.join('/'),
          file: dataFile,
        })
      )
      if (res.error) throw new Error(res.payload)
      setLoading(false)
      setIsPopupOpen(false)
      showMessage('File uploaded successfully', MessageType.Success)
    } catch (error) {
      setLoading(false)
      setIsConsentPopup(true)
      dispatch(updateReadWriteField({ isReadWrite: false }))
      console.error(error)
    }
  }

  const state = {
    viewerLoaded,
    initialized,
    pageCount,
    zoom,
    currentPage,
    viewerError,
    documentOutline,
    showAnnotationbar,
    passwordReqired,
    fileBlob,
    modules,
  }

  const values = [state, actions, createPdfViewer]

  return (
    <PdfViewerContext.Provider value={values}>
      {isConsentPopup && (
        <NeeConsentPopup onClose={() => setIsConsentPopup(false)} />
      )}
      {isPopupOpen && (
        <Popup
          title={'Select Location'}
          onClose={() => setIsPopupOpen(false)}
          isCustom={true}
          footer={() => {
            return (
              <ButtonRow center noWrap>
                <Button
                  color="primary"
                  id="btnBarcodeOK"
                  onClick={() => checkConsent(fileUploadHandler)}
                  loading={loading}
                  // disabled={barcodeInvalid}
                >
                  Upload
                </Button>
                <Button onClick={() => setIsPopupOpen(false)}>Cancel</Button>
              </ButtonRow>
            )
          }}
        >
          <Content>
            <OneDriveFilePicker show className="file__picker__pdfeditor" />
          </Content>
        </Popup>
      )}
      {children}
    </PdfViewerContext.Provider>
  )
})

export default PdfViewerContext
